<template>
  <Loading v-if="cssLoader && cssLoader[$route.params.slug] !== false" />

  <div
    v-if="cssLoader && cssLoader[$route.params.slug] == false"
    class="lg:relative"
  >
    <div class="lg:m-2 md:m-6 mx-3 mt-3">
      <div class="justify-between">
        <div
          :class="{
            'md:text-md text-sm top-rappers-text': true,
            'text-black': currentRouteName != 'TopRappersWithState',
            'text-white': currentRouteName == 'TopRappersWithState',
          }"
          v-if="states && states[0]"
        >
          Top artists
          {{
            currentRouteName == "TopRappersWithState"
              ? " in " + states[0].name
              : ""
          }}:
          <span class="text-white">
            <select
              name="week"
              class="p-1 text-base placeholder-white rapbg border-rounded focus:outline-none"
              @change="topArtist()"
              v-model="RunningWeek"
            >
              <option
                v-for="selectweek in week"
                :key="selectweek"
                :value="selectweek.week"
              >
                {{ selectweek.start_date }} to {{ selectweek.end_date }}
              </option>
            </select>
          </span>
        </div>
        <div v-if="topArtists.length <= 0">
          <p class="text-center p-5">
            Help us find {{ states[0].name }} artists. Tell them the first 50
            artists who sign up are free!
          </p>
        </div>
        <!-- <div class="flex">
                        <img src="../../assets/image/ellipsis.svg" alt="" class=" lg:w-8 md:w-12 md:h-12 w-8 h-6 justify-self-end">
                    </div> -->
      </div>
      <router-link
        class="grid grid-cols-2 mt-3 gap-3 users items-center"
        v-for="(artist, index) in topArtists"
        :key="index"
        :to="{ name: 'ProfileWithSlug', params: { slug: artist.slug } }"
      >
        <div class="items-center">
          <img
            src="../../assets/image/default.png"
            alt=""
            class="rounded-full responsive-image profile-image-list float-left"
            v-if="artist.image == null || artist.image == ''"
          />
          <img
            onerror="this.onerror=null;this.src=document.getElementById('defaultImage').src;"
            :src="media_base_url + artist.image"
            alt=""
            class="rounded-full responsive-image profile-image-list float-left"
            v-else
          />
          <p class="pl-2 artist-list-name text-sm truncate mt-1">
            {{ index + 1 }}. {{ artist.member_name }}
          </p>
        </div>
        <div
          :class="{
            'text-right w-full text-sm': true,
            'text-white': currentRouteName == 'TopRappersWithState',
          }"
        >
          <p>
            {{ artist.vote_count }}
            <span v-if="artist.vote_count == 1">Vote</span
            ><span v-else>Votes</span>
          </p>
        </div>
      </router-link>
      <div
        class="text-right my-4"
        v-if="currentRouteName != 'TopRappersWithState'"
      >
        <router-link
          class="text-sm top-rappers-text text-white cursor-pointer"
          :to="{ name: 'Artists' }"
          v-if="artistCount > 0"
          @click="display"
        >
          View all
        </router-link>
      </div>
      <!-- <hr class="border-gray-300 mt-4"> -->
      <div
        class="border-b-2 border-black mx-3 m-5"
        v-if="currentRouteName != 'TopRappersWithState'"
      ></div>
      <!-- <div class="pb-2" v-if="currentRouteName != 'TopRappersWithState'">
                    <div class="text-center my-8">
                        <form @submit.prevent="search">
                            <input type="text" name="" id="" placeholder="SEARCH FOR ARTISTS..." 
                                class="placeholder-black font-bold text-black xl:w-10/12 md:w-7/12 w-9/12 text-md p-2 md:p-4 text-center
                                bg-gray-100 rounded-xl focus:outline-none"
                                v-model="searchStr"
                            >
                        </form>
                    </div>
                    <div class="grid grid-cols-3 gap-3">
                        <div class="text-center" v-for="state in states" :key="state.id">
    
                            <router-link :to="{ name : 'TopRappersWithState',params : { slug : state.slug } }" v-if="state.status == 1"
                                class='justify-center items-center w-full h-full rounded-md px-1 py-1 break-word bg-black text-white py-2 truncate state-btn'
                            >
                                {{ state.name }}
                            </router-link>
    
                            <a href="#" v-else
                                class='justify-center items-center w-full h-full rounded-md px-1 py-1 break-word bg-gray-400 py-2 truncate state-btn'
                            >
                                {{ state.name }}
                            </a>
                        </div>
                        
                    </div>
                </div> -->
    </div>
  </div>
</template>

<script>
import API from "../../api/http";
import Loading from "../Shared/Loading";
export default {
  name: "TopRappers",
  components: {
    Loading,
  },
  data() {
    return {
      media_base_url: process.env.VUE_APP_MEDIA_BASE_URL,
      show: false,
      // states: [],
      // topArtists: [],
      viewmore: false,
      searchStr: "",
      // week: null,
      getTopArtistUrl: "top-artists",
      artistCount: null,
      loading: false,
      RunningWeek: 0,
      // currentWeek:0,
    };
  },
  watch: {
    $route() {
      // if(newValue.name == 'TopRappers'){
      //     }
      this.loading = true;
      this.topArtist();
    },
  },
  computed: {
    user() {
      return this.$store.getters["User/user"];
    },
    currentRouteName() {
      return this.$route.name;
    },
    states() {
      return this.$store.getters["Toprappersstate/states"][
        this.$route.params.slug
      ];
    },
    topArtists() {
      return this.$store.getters["Toprappersstate/topArtists"][
        this.$route.params.slug
      ];
    },
    week() {
      return this.$store.getters["Toprappersstate/week"][
        this.$route.params.slug
      ];
    },
    currentWeek() {
      return this.$store.getters["Toprappers/currentWeek"];
    },
    cssLoader() {
      return this.$store.getters["Toprappersstate/cssLoader"];
    },
  },
  created() {
    this.RunningWeek = this.currentWeek;
    this.topArtist(this.getTopArtistUrl);
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
  },
  methods: {
    display() {
      this.show = !this.show;
      if (this.show == false) {
        document.getElementById("view-all").innerHTML = "VIEW ALL";
        this.topArtist(this.getTopArtistUrl);
      } else {
        document.getElementById("view-all").innerHTML = "VIEW LESS";
        this.topArtist(this.getTopArtistUrl + "?filter=all");
      }
    },
    topArtist() {
      if (this.$route.name == "TopRappers") {
        API.get("top-artists").then((response) => {
          this.loading = false;
          this.states = response.states;
          this.topArtists = response.top_artists;
          this.week = response.week;
          this.artistCount = response.artist_count;
        });
      } else {
        API.get(
          "show-artist-state/" +
            this.$route.params.slug +
            "?week=" +
            this.RunningWeek
        ).then((response) => {
          this.loading = false;
          // this.topArtists = response.top_artists[0].user;
          // this.states = response.top_artists;
          // this.week = response.week;

          this.$store.commit("Toprappersstate/SET_STATES", response);
          this.$store.commit("Toprappersstate/SET_ARTISTS", response);
          this.$store.commit("Toprappersstate/SET_WEEK", response);
          if (this.currentWeek == 0) {
            this.RunningWeek =
              response.current_year_weeks[
                response.current_year_weeks.length - 1
              ].week;
            this.$store.commit(
              "Toprappers/SET_CURRENT_WEEK",
              response.current_year_weeks[
                response.current_year_weeks.length - 1
              ].week
            );
          } else {
            this.$store.commit("Toprappers/SET_CURRENT_WEEK", this.RunningWeek);
          }

          this.$store.commit("Toprappersstate/SET_CSS_LOADER", {
            status: false,
            slug: this.$route.params.slug,
          });
        });
      }
    },
    search() {
      if (this.search != "") {
        this.$router.push({
          name: "Search",
          params: {
            string: this.searchStr,
          },
        });
      }
    },
    removeUser(e) {
      e, this.topArtist();
    },
  },
};
</script>
